import MenuDrawer from '../components/global/MenuDrawer';
import PartnersBox from '../components/partners/PartnersBox';
import { partnersData } from '../data/partnersData';
import { Box } from '@mui/material';

function PartnersPage() {

  return (
    <>
      <MenuDrawer />
      <Box sx={{width:"100%", height:"100%", backgroundColor:"white"}}>
        {partnersData.map((partner, index) => (
          <PartnersBox key={index} images={partner.images} textGr={partner.text.gr} textEn={partner.text.en} marginTop={partner.marginTop} />
        ))}
      </Box>
    </>
  );
}

export default PartnersPage;
