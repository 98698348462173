import { Typography, Divider } from '@mui/material';
import MenuDrawer from '../components/global/MenuDrawer';
import { useLocaleState } from '../context/LocaleStateContext';

function EventsPage(){
  const { locale } = useLocaleState();

  function isEnLocale() {
    return locale==="en";
  }

  return (
    <>
      <MenuDrawer />
      <Typography 
        variant="h4" 
        align="center" 
        style={{ 
          fontWeight: '550' , 
          textDecoration:'underline',
          marginBottom: '60px'
        }}
      >
        {isEnLocale() ? "Events" : "Δράσεις"}
      </Typography>    

      <div style={{ marginBottom: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <img src="PIPF_ALKYONE_POST-1-300x300.jpg" alt="event" />
      </div>

      <Typography 
        variant="h5" 
        align="center" 
        style={{ 
          fontWeight: '550' , 
        }}
      >
        {isEnLocale() ? "Opening & Alkyone live, Saturday 28.9 at 21:00" : "Επίσημη έναρξη & Alkyone live, Σάββατο 28.9 στις 21:00"}
      </Typography>
    
      <Typography align="center" margin={"20px"} >
        {isEnLocale() ? 
          "Photometria International Photography Festival 2024, hosts mοre than 50 artists in 11 exhibition venues in the city of Ioannina. Photometria Photography Center, the festival’s permanent headquarters, hosts one of the most emblematic exhibitions of the festival for 2024. As every year, the program is rich in new photographic proposals, high-level lectures and activities for visitors and citizens of Ioannina. At the end of the festival, a selection of exhibitions is presented to various cities around the country and abroad."
          :
          "To διεθνές φεστιβάλ φωτογραφίας Photometria 2024 φιλοξενεί πάνω από 50 καλλιτέχνες σε 11 σημεία ιστορικού και εικαστικού ενδιαφέροντος στην πόλη των Ιωαννίνων. Το Photometria Photography Center, η μόνιμη έδρα του φεστιβάλ που λειτουργεί καθ’ όλη τη διάρκεια του χρόνου, φιλοξενεί και φέτος μία από τις πιο εμβληματικές εκθέσεις της διοργάνωσης. To πρόγραμμα, όπως κάθε χρόνο, είναι πλούσιο σε νέες φωτογραφικές προτάσεις, διαλέξεις υψηλού επιπέδου και δράσεις για τους επισκέπτες και τους πολίτες των Ιωαννίνων. Μετά το πέρας του φεστιβάλ, επιλεγμένες εκθέσεις ταξιδεύουν σε διάφορες πόλεις της Ελλάδας και του εξωτερικού."
        }
      </Typography>

      <Typography marginBottom= '20px' textAlign='center'>
          <strong>{isEnLocale() ? "Free participation" : "Είσοδος ελεύθερη"}</strong> 
      </Typography>

      <Divider />

      <div style={{ marginTop: '50px', marginBottom: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <img src="eventsImg2.png" alt="event" />
      </div>

      <Typography 
        variant="h5" 
        align="center" 
        style={{ 
          fontWeight: '550' , 
        }}
      >
        {"Schoolaroid"}
      </Typography>
    
      <Typography align="center" margin={"20px"} >
        {isEnLocale() ? "Educational activity for primary school children on the medium of photography and visual arts" : "Εκπαιδευτική δράση για παιδιά δημοτικού πάνω στο μέσο της φωτογραφίας και των οπτικών τεχνών"}
      </Typography>
    
      <Typography marginBottom= '20px' textAlign='center'>
          <strong>{isEnLocale() ? "Theater of the Municipal Cultural Multiplex “D. Chatzis”" : "Θέατρο Πολιτιστικού Πολυχώρου «Δημ. Χατζής»"} </strong> 
      </Typography>
        
      <Typography marginBottom= '20px' textAlign='center'>
          <strong>{isEnLocale() ? "Saturday 5.10, 17:00" : "Σάββατο 5.10, 17:00"} </strong>
      </Typography>
      <Typography marginBottom= '20px' textAlign='center'>
          <strong>{isEnLocale() ? "Free participation" : "Είσοδος ελεύθερη"}</strong> 
      </Typography>

      <Divider />

      <div style={{ marginTop: '50px', marginBottom: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <img src="events.png" alt="event" />
      </div>

      <Typography 
        variant="h5" 
        align="center" 
        style={{ 
          fontWeight: '550' , 
        }}
      >
        {"Photometria Walk"}
      </Typography>
    
      <Typography align="center" margin={"20px"} >
        {isEnLocale() ? "Photo contest / Treasure hunt" : "Διαγωνισμός φωτογραφίας / Kυνήγι θησαυρού"}
      </Typography>
    
      <Typography marginBottom= '20px'  marginLeft="20px">
          <strong>{isEnLocale() ? "Date: " : "Ημερομηνία διεξαγωγής: "} </strong> 
          {"6.10.2024"}
      </Typography>
        
      <Typography marginBottom= '20px'  marginLeft="20px">
          <strong>{isEnLocale() ? "Duration: " : "Διάρκεια: "} </strong>
          {"15:00 – 20:00"}
      </Typography>
      <Typography marginBottom= '20px' marginLeft="20px">
          <strong>{isEnLocale() ? "Registration: " : "Εγγραφή: "}</strong> 
          {"15:00 – 16:00"}
      </Typography>
      <Typography marginBottom= '20px' marginLeft="20px">
          <strong>{isEnLocale() ? "Theme announcement: " : "Ανακοίνωση θέματος: "}</strong> 
          {"16:00"}
      </Typography>
      <Typography marginBottom= '20px' marginLeft="20px">
          <strong>{isEnLocale() ? "Photowalking & submission: " : "Φωτογράφιση & κατάθεση διαγωνιζόμενης φωτογραφίας: "}</strong> 
          {"16:00 – 19:00"}
      </Typography>
      <Typography marginBottom= '20px' marginLeft="20px">
          <strong>{isEnLocale() ? "Results announcement: " : "Ανακοίνωση αποτελεσμάτων: "}</strong> 
          {"20:00"}
      </Typography>

      <Typography marginBottom= '20px'  marginLeft="20px">
          <strong>{isEnLocale() ? "Place of registration & submission: " : "Χώρος εγγραφής και κατάθεσης διαγωνιζόμενης φωτογραφίας: "}</strong> 
          {isEnLocale() ? "Theater of the Municipal Cultural Multiplex “D. Chatzis”" : "Θέατρο Πολιτιστικού Πολυχώρου «Δημ. Χατζής»"}
      </Typography>
      <Typography marginBottom= '20px'  marginLeft="20px">
          <strong>{isEnLocale() ? "Participation: " : "Συμμετοχή: "}</strong>
          {isEnLocale() ? "Free" : "Δωρεάν"}
      </Typography>
      <Typography marginBottom= '20px'  marginLeft="20px">
          <strong>{isEnLocale() ? "Accepted files: " : "Τύπος αρχείων φωτογραφιών: "}</strong>
          {isEnLocale() ? "Mobile or camera, color or black and white, any resolution, previous shots." : "Μπορούν να χρησιμοποιηθούν φωτογραφίες που έχουν τραβηχτεί και παλαιότερα και μπορούν να έχουν οποιαδήποτε ανάλυση (μέσω κινητού ή κάμερας). Γίνονται δεκτές είτε έγχρωμες είτε ασπρόμαυρες φωτογραφίες."}
      </Typography>
      <Typography marginBottom= '100px'  align='center'>
          <strong>{isEnLocale() ? "" : "Η καλύτερη συμμετοχή κερδίζει!"}</strong>
      </Typography>
    </>
  );
}

export default EventsPage;