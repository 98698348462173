import { Typography } from '@mui/material';
import MenuDrawer from '../components/global/MenuDrawer';
import { useLocaleState } from '../context/LocaleStateContext';

function ContactPage() {
  const { locale } = useLocaleState();

  function isEnLocale() {
    return locale==="en";
  }

  return (
    <>
      <MenuDrawer />

      <Typography align="center" sx={{marginTop:20, marginBottom:5, fontSize: 20}} fontFamily='Comfortaa' >
        {isEnLocale() ? "CONTACT INFO" : "ΣΤΟΙΧΕΙΑ ΕΠΙΚΟΙΝΩΝΙΑΣ"}
      </Typography>

      <Typography marginBottom= '30px'  marginLeft="20px">
        <strong>{isEnLocale() ? "General Informations: " : "Γενικές Πληροφορίες: "} </strong> 
        {"info@photometria.gr"}
      </Typography>
      <Typography marginBottom= '30px'  marginLeft="20px">
        <strong>{isEnLocale() ? "Sponsorships & Public relations: " : "Χορηγίες & Δημόσιες σχέσεις: "} </strong> 
        {"press@photometria.gr"}
      </Typography>
      <Typography marginBottom= '30px'  marginLeft="20px">
        <strong>{isEnLocale() ? "Photometria Awards Competition: " : "Διαγωνισμός Photometria Awards: "} </strong> 
        {"competition@photometria.gr"}
      </Typography>
      <Typography marginBottom= '30px'  marginLeft="20px">
        <strong>{isEnLocale() ? "Portfolio Competitions: " : "Διαγωνισμοί Portfolio: "} </strong> 
        {"portfolio@photometria.gr"}
      </Typography>
      <Typography marginBottom= '30px'  marginLeft="20px">
        <strong>{isEnLocale() ? "Screenings: " : "Τμήμα προβολών: "} </strong> 
        {"films@photometria.gr"}
      </Typography>
      <Typography marginBottom= '30px'  marginLeft="20px">
        <strong>{isEnLocale() ? "Educational Programs: " : "Εκπαιδευτικά Προγράμματα: "} </strong> 
        {"seminars@photometria.gr"}
      </Typography>
    </>
  );
}

export default ContactPage;