import { MapContainer, TileLayer, useMap } from "react-leaflet";
import { Box, Alert } from "@mui/material";
import { useEffect, useState } from "react";
import { exhibitionsData } from "../../data/exhibitionsData";
import Loading from "../global/Loading";
import Error from "../global/Error";
import 'leaflet/dist/images/marker-icon.png';
import 'leaflet/dist/images/marker-shadow.png';
import 'leaflet/dist/leaflet.css';
import UserLocation from "./UserLocation";
import ExhibitionsMarker from "./ExhibitionMarker";

function ExhibitionsMap() {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [exhibitions, setExhibitions] = useState([]);
  const [destinationCoordinates, setDestinationCoordinates] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [userPosition, setUserPosition] = useState();

  useEffect(() => {
    const runQuery = async () => {
      try {
        setExhibitions(exhibitionsData);
      } catch (err) {
        setError(err);
      } finally {
        setIsLoading(false);
      }
    };
    runQuery();
  }, []);

  function getExhibitionsByLocation() {
    const exhibitionsByLocation = exhibitions.reduce((acc, exhibition) => {
      const location = exhibition.attributes.location;
      if (location) {
        const key = `${location.latitude},${location.longitude}`;
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(exhibition);
      }
      return acc;
    }, {});
    return exhibitionsByLocation;
  }

  function getBounds() {
    const bounds = Object.keys(getExhibitionsByLocation()).map(key => {
      const [longitude, latitude] = key.split(',').map(Number);
      return [latitude, longitude];
    });
    return bounds;
  }

  function FlyToBoundsOnLoad({ bounds }) {
    const map = useMap();
    useEffect(() => {
      if (bounds && bounds.length > 0) {
        map.flyToBounds(bounds, { 
          duration: 1.5,
          padding: [100, 100]
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ map]);
    return null;
  };

  if (isLoading) return <Loading />;
  if (error) return <Error />;

  return (
    <>
    {showAlert &&
      <Alert 
        severity="info" 
        sx={{ position: 'absolute', top: 0, width: '90%', zIndex: 1000 }}
      >
        {"Enable pop-up windows through settings to use the navigation feature."}
      </Alert>
    }
    <Box sx={{ width: '100vw', height: '85vh', position: 'fixed', bottom: 0}}>
      <MapContainer 
        center={[0, 0]} 
        zoom={0}
        style={{ height: '100%', width: '100%' }} 
        zoomControl={false}
      >
        <FlyToBoundsOnLoad bounds={getBounds()} />
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {Object.entries(getExhibitionsByLocation()).map(([key, exhibition]) => {
          const [longitude, latitude] = key.split(',').map(Number);
          const position = [latitude, longitude];
          const thumbnail = exhibition[0].attributes.thumbnail;
          const title = exhibition[0].attributes.title;
          const location = exhibition[0].attributes.location.name;

          return (
            <ExhibitionsMarker 
              key={key}
              position={position}
              exhibition={exhibition}
              title={title}
              thumbnail={thumbnail}
              location={location}
              destinationCoordinates={destinationCoordinates}
              setDestinationCoordinates={setDestinationCoordinates}
              setShowAlert={setShowAlert}
              userPosition={userPosition}
            />
          );
        })}
        <UserLocation 
          destinationCoordinates={destinationCoordinates}
          userPosition={userPosition}
          setUserPosition={setUserPosition}
        />
      </MapContainer>
    </Box>
    </>
  );
}

export default ExhibitionsMap;