import { useState, useEffect } from "react";
import Loading from "../global/Loading";
import Error from "../global/Error";
import { Grid, Box, Typography, Avatar } from "@mui/material";
import PhotographerDialog from "./PhotographerDialog";
import { colors } from "../../config/colors";
import { photographersData } from "../../data/photographersData";
import { useLocaleState } from "../../context/LocaleStateContext";

function PhotographersContent() {
  const { locale } = useLocaleState();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [photographers, setPhotographers] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedPhotographer, setSelectedPhotographer] = useState(null);

  useEffect(() => {
    const runQuery = async () => {
      try {
        setPhotographers(photographersData);
      } catch (err) {
        setError(err);
      } finally {
        setIsLoading(false);
      }
    };
    runQuery();
  }, []);

  function handlePhotographerClick(photographer) {
    setSelectedPhotographer(photographer);
    setOpen(true);
  };

  function getLocale(item) {
    if (locale==="en") return item.en;
    return item.gr;
  }

  if (isLoading) return <Loading />;
  if (error) return <Error />;
  if (photographers.length === 0) return <>No content available!</>;

  return (
    <>
      <Grid container spacing={4} sx={{ padding: 4, marginTop: 8 }}>
        {photographers.map((photographer) => {
          const title = photographer.attributes.title;
          const description = photographer.attributes.description;
          const imageUrl = photographer.attributes.imageUrl;

          return (
            <Grid item xs={12} sm={6} md={4} key={photographer.id} >
              <Box
                sx={{
                  marginBottom: 1.5,
                  padding: 1.5,
                  boxShadow: '0 3px 6px rgba(0, 0, 0, 0.1)',
                  borderRadius: 2,
                  backgroundColor: colors.lightYellow, 
                  minWidth: 190,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={() => handlePhotographerClick({ title, description, imageUrl })}
              >
                <Typography
                  sx={{
                    fontSize: '1rem',
                    fontWeight: 'bold',
                    textAlign: 'center',
                    marginBottom: 2,
                  }}
                  fontFamily='Comfortaa'
                >
                  {getLocale(title)}
                </Typography>
                <Avatar 
                  alt={getLocale(title)} 
                  src={imageUrl} 
                  sx={{
                    width: 190,
                    height: 190,
                    justifyContent: 'center',
                  }}
                />
              </Box>
            </Grid>
          );
        })}
      </Grid>
      <PhotographerDialog
        selectedPhotographer={selectedPhotographer}
        setSelectedPhotographer={setSelectedPhotographer}
        open={open}
        setOpen={setOpen}
      />
    </>
  );
}

export default PhotographersContent;