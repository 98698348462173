import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { API_CALL_DELAY } from '../../config/api';
import Loading from '../global/Loading';
import Error from '../global/Error';
import { aboutUsData } from '../../data/aboutUsData';
import { useLocaleState } from "../../context/LocaleStateContext";

function AboutUsContent() {
  const { locale } = useLocaleState();
  const [informations, setInformations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const runQuery = async () => {
      try {
        setInformations(aboutUsData);
      } catch (error) {
        setError(error.message);
      } finally {
        setTimeout(() => setIsLoading(false), API_CALL_DELAY);
      }
    };
    runQuery();
  }, []);

  function isEnLocale() {
    return locale==="en";
  }

  function getLocale(item) {
    if (locale==="en") return item.en;
    return item.gr;
  }

  if (isLoading) return <Loading />;
  if (error) return <Error />;

  return (
    <Box sx={{ padding: 4 }}>
      <Typography variant="h4" marginTop={"100px"} gutterBottom fontFamily='Comfortaa'>
        {isEnLocale() ? "About Us"  : "Πληροφορίες"} 
      </Typography>

      <Typography marginTop={5} variant="body1" paragraph>
        {isEnLocale() ? 
          "Photometria International Photography Festival is organized by “Entefxis”, a non-profit organization. Organized for the 16th year in the city of Ioannina is the first interactive Photography Festival in which everyone who loves the art of photography can participate." 
          : 
          "Το Photometria International Photography Festival είναι μια διοργάνωση της αστικής, μη κερδοσκοπικής «Έντευξις». Διοργανώνεται για 16η χρονιά στην πόλη των Ιωαννίνων και πρόκειται για το πρώτο διαδραστικό Φεστιβάλ στο οποίο έχουν την δυνατότητα να συμμετέχουν όσοι αγαπούν την τέχνη της φωτογραφίας."
        }
      </Typography>
      <Typography variant="body1" paragraph >
        {isEnLocale() ?
          "Our vision is for Photometria International Photography Festival to become a showcase of contemporary photography, to support local and foreign photographers, of education and cultivation of partnerships with cultural organizations and festivals in Greece and abroad. Hopefully, Photometria International Photography Festival is a cross-cultural meeting point, bringing ideas, traditions, worldviews, and expressive media from all over the world. With dedication to the project and wishing for its establishment of the organizers call for participation, not only renowned photographers but also amateurs to take part in the field of expression."
          :
          "Όραμά μας είναι το Photometria International Photography Festival να αποτελέσει πεδίο προβολής της σύγχρονης φωτογραφίας, της υποστήριξης νέων Ελλήνων και ξένων φωτογράφων, της εκπαίδευσης, καθώς και της καλλιέργειας συνεργασιών με πολιτιστικούς οργανισμούς και φεστιβάλ της Ελλάδας και άλλων χωρών."
        }
      </Typography>
      <Typography variant="body1" paragraph>
        {isEnLocale() ?
          "Our expectation is for Photometria Festival to be a cultural umbrella under which photographic expression and creativity grows. Get ready for another Photometria Festival full of creativity, exhibitions, seminars and photographic wanderings."
          :
          "Ευελπιστούμε το Photometria International Photography Festival να είναι ένας τόπος δια-πολιτιστικής συνάντησης, μεταφέροντας αντιλήψεις, παραδόσεις, κοσμοθεωρίες, και εκφραστικά μέσα από όλα τα σημεία του πλανήτη. Με αφοσίωση στο εγχείρημα και ευχόμενοι την καθιέρωση του, οι διοργανωτές θέσαμε από την πρώτη στιγμή ως στόχο την πρόσκληση για συμμετοχή, όχι μόνο καταξιωμένων φωτογράφων αλλά και ερασιτεχνών να λάβουν μέρος στο πεδίο της έκφρασης. Προσδοκία μας είναι να αποτελέσει το Photometria International Photography Festival μία πολιτιστική ομπρέλα κάτω από την οποία θα καλλιεργείται η φωτογραφική έκφραση και η δημιουργικότητα. Ετοιμαστείτε λοιπόν για άλλο ένα Photometria γεμάτη δημιουργικότητα, εκθέσεις, σεμινάρια και φωτογραφικές περιπλανήσεις."
        }
      </Typography>

      <Typography marginTop={5} marginBottom={2} variant="h5" gutterBottom fontFamily='Comfortaa'>
        {isEnLocale() ? "Contributors" : "Συντελεστές"}
      </Typography>

      {informations.map((info, index) => (
        <Typography key={index} variant="body1" paragraph>
          <strong>{info.attributes.role && getLocale(info.attributes.role)}</strong>: {info.attributes.name && getLocale(info.attributes.name)}
        </Typography>
      ))}

      <Typography marginTop={7} />

      {informations.slice(0, 8).map((information, index) => {
        const image = information.attributes.image;
        const role = information.attributes.role;
        const description = information.attributes.description;

        return (
          <Box key={index} sx={{ marginBottom: 2 }}>
            <Typography variant="body1" component="span" fontWeight="bold">
              {getLocale(role)}
            </Typography>
            <img 
              src={image} 
              alt={"img"} 
              style={{ 
                width: '100%', 
                height: 'auto', 
                maxWidth: '150px', 
                display: 'block', 
                marginTop: '8px'  
              }} 
            />
            <Typography variant="body1" component="span" >
              {getLocale(description)}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
}

export default AboutUsContent;
