export const menuItems = [
  {
    name: {
      gr: "ΧΑΡΤΗΣ", 
      en: "MAP"
    },
    route: "/map"
  },
  {
    name: {
      gr: "ΕΚΘΕΣΕΙΣ",
      en: "EXHIBITIONS"
    },
    route: "/exhibitions"
  },
  {
    name: {
      gr: "ΔΡΑΣΕΙΣ",
      en: "EVENTS"
    },
    route: "/events"
  },
  {
    name: {
      gr: "ΠΡΟΒΟΛΕΣ", 
      en: "SCREENINGS"
    },
    route: "/screenings"
  },
  {
    name: {
      gr: "ΔΙΟΡΓΑΝΩΣΗ",
      en: "ABOUT US"
    },
    route: "/about-us"
  }
]

export const menuDrawerItems = [
  [
    {
      name: {
        gr: "ΜΕΝΟΥ",
        en: "MENU",
      },
      route: "/introduction-menu",
    },
    {
      name: {
        gr: "ΕΚΘΕΣΕΙΣ",
        en: "EXHIBITIONS"
      },
      route: "/exhibitions",
    },
    {
      name: {
        gr: "ΠΡΟΒΟΛΕΣ", 
        en: "SCREENINGS"
      },
      route: "/screenings",
    },
    {
      name: {
        gr: "ΔΡΑΣΕΙΣ",
        en: "EVENTS"
      },
      route: "/events",
    },
    {
      name: {
        gr: "ΧΑΡΤΗΣ", 
        en: "MAP"
      },
      route: "/map",
    },
  ],
  [
    {
      name: {
        gr: "ΦΩΤΟΓΡΑΦΟΙ",
        en: "PHOTOGRAPHERS"
      },
      route: "/photographers",
    },
    {
      name: {
        gr: "ΔΙΟΡΓΑΝΩΣΗ",
        en: "ABOUT US"
      },
      route: "/about-us",
    },
    {
      name: {
        gr: "ΕΠΙΚΟΙΝΩΝΙΑ",
        en: "CONTACT"
      },
      route: "/contact",
    },
    {
      name: {
        gr: "ΧΟΡΗΓΟΙ",
        en: "PARTNERS"
      },
      route: "/partners",
    },
  ]
]