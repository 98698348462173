export const partnersData = [
  {
    "text": {
      "gr": "Με την οικονομική υποστήριξη και υπό την αιγίδα του Υπουργείου Πολιτισμού",
      "en": "With the financial support and auspices of the Ministry of Culture"
    },
    "images": ["yppo_logo-2-1536x428.jpg"],
    "marginTop": 15
  },
  {
    "text": {
      "gr": "Υπό την αιγίδα και την οικονομική υποστήριξη της Περιφέρειας Ηπείρου και του Δήμου Ιωαννιτών",
      "en": "Under the auspices and with the financial support of the Region of Epirus and the Municipality of Ioannina"
    },
    "images": ["per_hpeirou.svg_-300x211.png", "sima_dimos-250x250.jpg"],
    "marginTop": 1 
  },
  {
    "text": {
      "gr": "ΕΚΤΥΠΩΣΕΙΣ",
      "en": "PRINTS"
    }, 
    "images": ["logo-KOSTIS-MOUSELIMIS-2-1-scaled-250x250.jpg", "555_page-0001-300x232.jpg"],
    "marginTop": 1
  }, 
  {
    "text": {
      "gr": "ΑΣΗΜΕΝΙΟΣ ΧΟΡΗΓΟΣ",
      "en": "SILVER SPONSOR"
    }, 
    "images": ["epirus-bank-Logo_Registred_FINAL_GR_page-0001-495x128.jpg"],
    "marginTop": 1
  },    
  {
    "text": {
      "gr": "ΧΟΡΗΓΟΙ ΦΙΛΟΞΕΝΙΑΣ",
      "en": "ACCOMODATION SPONSORS"
    }, 
    "images": [
      "vilielmini-300x291.jpg", "TELIKO-LOGO-ERMIS-273x300.png", "montana-logo-black_page-0001-1-300x300.jpg",
      "zinn_logo_horizontal-300x90.png", "logo-olympic2-300x155.jpg", "hotelpalladion-300x83.png",
    ],
    "marginTop": 1
  }, 
  {
    "text": {
      "gr": "ΧΟΡΗΓΟΣ ΕΣΤΙΑΣΗΣ",
      "en": "DINING SPONSOR"
    }, 
    "images": ["new-logobalsamico-300x113.png"],
    "marginTop": 1 
  },     
  {
    "text": {
      "gr": "ΧΟΡΗΓΟΙ ΕΠΙΚΟΙΝΩΝΙΑΣ",
      "en": "MEDIA SPONSORS"
    }, 
    "images": [
      "1ert3_green-300x90.png", "ERT_IOANNINA-300x169.png", "pb-news-logo2-300x88.jpg",
      "dimrad.png", "1o_logo-300x300.png", "SUPERFMLOGO-NEO-300x86.jpg",
      "Πρωινός-Λόγος-300x46.jpg", "Typos_Ioanninon-300x192.png", "ΗΠΕΙΡΩΤΙΚΟΣ-ΑΓΩΝ_τίτλος-300x37.jpg",
      "i-FOCUS-logo-2-1-300x212.jpeg", "Fotografos-1-300x297.jpg", "nexus-media-png-300x216.png",
      "fmag-300x114.png", "Photologio_Logo_Black.png", "PTTL_LOGO_WHITE_PNG-copy-300x121.png"
    ],
    "marginTop": 1 
  },
  {
    "text": {
      "gr": "ΧΟΡΗΓΟΙ",
      "en": "SPONSORS"
    }, 
    "images": [
      "logotiponeaodos-300x117.jpg", "Vektor_nikon_grad-250x250.jpg", "total-advice-rgb-300x300.png",
      "ilektragora-logo-simaia-2mx60cm-300x90.jpg", "01_KERKYRAIKI_PRIMARY_LOGO_RGB-300x277.jpg", "logo.png"
    ],
    "marginTop": 1 
  },
  {
    "text": {
      "gr": "ΥΠΟΣΤΗΡΙΚΤΕΣ",
      "en": "SUPPORTERS"
    }, 
    "images": [
      "ΕΦΟΡΕΙΑ-ΑΡΧΑΙΟΤΗΤΩΝ-ΙΩΑΝΝΙΝΩΝ-LOGO-300x115.jpg", "LogoMOMusPhotographyENG_page-0001-1-scaled-700x200.jpg", "Cultural_Foundation_Logo_Green-300x109.png",
      "Κεντρο-Νεων-Ηπειρου-300x107.png", "BCHCS_logo-300x294.png", "esp-saek-1-300x300.png",
      "Embajada-Atenas-CE-alta-resolucion_page-0001-300x104.jpg", "griekija_gr_page-0001-300x192.jpg", "logo-lemmth-300x219.jpg",
      "LOGO-300x294.jpg", "Consulate-logo-circular-web-300x300.jpg", "folkways_logo_black-bw-sunburst-bottom-237x300.png",
      "ZOETROPE-ATHENS-LOGO-300x51.png", "CompArt-Factory-225x300.jpg", "Gallery-3PORTES-png-6400-no-background-300x142.png"
    ],
    "marginTop": 1 
  },
]