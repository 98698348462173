export const screeningsData = [
  {
    "id": 0,
    "attributes": {
      "title": {
        "gr": "Δευτέρα 30/9, 20:00",
        "en": "Monday 30/9, 20:00"
      },
      "subtitle": {
        "gr": "Photometria in Motion, Κατηγορία: Ταινίες Μικρού Μήκους (1ο μέρος), 144′",
        "en": "Photometria in Motion, Category: Short Films (1st part), 144′"
      },
      "description": {
        "gr":
          "• Hunting, 5′, Maïa Aboualiten [Γαλλία]\n\n" +
          "• WE ARE ALL DOGS, 14′, Aminreza Alimohammadi [Ιράν]\n\n" +
          "• Being one, 17′18′′, Juan Pablo Aróstegui [Μεξικό]\n\n" +
          "• We Are Happy, 19′26′′, Mohamad Boorboor [Ιράν]\n\n" +
          "• Homo, 7′58′′, Yaroslav Vyacheslavovich Naumenko [Ουκρανία]\n\n" +
          "• From the Dark, 6′53′′, Erika Sanz [Ην. Πολιτείες Αμερικής]\n\n" +
          "• From The Painter’s View, 16′22′′, Goli Zarei [Ιράν]\n\n" +
          "• Predators, 10′, Κυριάκος Αναστασίου [Ελλάδα]\n\n" +
          "• The Best, 10′, Ηλίας Δούλης [Ελλάδα]\n\n" +
          "• I WONDER,YES I CONFESS, I WONDER, 10′31′′, Αντωνία Οικονόμου, Χρήστος Ανδριανόπουλος [Ελλάδα]\n\n" +
          "• Another Day, 7′14′′, Alloprosallos Nous [Ελλάδα]\n\n" +
          "• Chlorine, 20′, Θάνος Ψυχογιός [Ελλάδα]",
        "en":
          "• Hunting, 5′, Maïa Aboualiten [France]\n\n" +
          "• WE ARE ALL DOGS, 14′, Aminreza Alimohammadi [Iran]\n\n" +
          "• Being one, 17′18′′, Juan Pablo Aróstegui [Mexico]\n\n" +
          "• We Are Happy, 19′26′′, Mohamad Boorboor [Iran]\n\n" +
          "• Homo, 7′58′′, Yaroslav Vyacheslavovich Naumenko [Ukraine]\n\n" +
          "• From the Dark, 6′53′′, Erika Sanz [USA]\n\n" +
          "• From The Painter’s View, 16′22′′, Goli Zarei [Iran]\n\n" +
          "• Predators, 10′, Kiriakos Anastasiou [Greece]\n\n" +
          "• The Best, 10′, Ilias Doulis [Greece]\n\n" +
          "• I WONDER,YES I CONFESS, I WONDER, 10′31′′, Antonia Economou, Christos Adrianopoulos [Greece]\n\n" +
          "• Another Day, 7′14′′, Alloprosallos Nous [Greece]\n\n" +
          "• Chlorine, 20′, Thanos Psichogios [Greece]",
      },
      "thumbnail": "media/HOMO-300x200.jpg"
    }
  },
  {
    "id": 1,
    "attributes": {
      "title": {
        "gr": "Τρίτη 1/10, 20:00",
        "en": "Tuesday 1/10, 20:00"
      },
      "subtitle": {
        "gr": "Photometria in Motion, Κατηγορία: Ταινίες Μικρού Μήκους (2ο μέρος), 139′",
        "en": "Photometria in Motion, Category: Short Films (2nd part), 139′"
      },
      "description": {
        "gr":
          "• THE SINGLE HORN, 13′28′′, Mohamad Kamal Alavi [Ιράν]\n\n" +
          "• Najan, 15′, M.Mahdi Bagheri [Ιράν]\n\n" +
          "• Fortissimo, 14′53′′, Victor Cesca [Γαλλία]\n\n" +
          "• Nonsense!, 20′′, Strahinja Mlađenović [Σερβία]\n\n" +
          "• Boycott, 13′16′′, Amin Rafie [Ιράν]\n\n" +
          "• The Land, 8′25′′, Masoud Shahri [Ιράν]\n\n" +
          "• School benches, 12′15′′, Behrouz Shamshiri [Ιράν]\n\n" +
          "• Father, 19′49′′, Steven Gekas [Ελλάδα]\n\n" +
          "• Axinos, 5′, Άννα Μαρία Κάκονα, Καλλιόπη Βίλλη Κωτούλα [Ελλάδα]\n\n" +
          "• Toy, 11′, Νατάσσα Σμυρναίου [Ελλάδα]\n\n" +
          "• Hantush – a fairytale, 6′12′′, Αλεξία Τσουνή [Ελλάδα]",
        "en": 
          "• THE SINGLE HORN, 13′28′′, Mohamad Kamal Alavi [Iran]\n\n" +
          "• Najan, 15′, M.Mahdi Bagheri [Iran]\n\n" +
          "• Fortissimo, 14′53′′, Victor Cesca [France]\n\n" +
          "• Nonsense!, 20′′, Strahinja Mlađenović [Serbia]\n\n" +
          "• Boycott, 13′16′′, Amin Rafie [Iran]\n\n" +
          "• The Land, 8′25′′, Masoud Shahri [Iran]\n\n" +
          "• School benches, 12′15′′, Behrouz Shamshiri [Iran]\n\n" +
          "• Father, 19′49′′, Steven Gekas [Greece]\n\n" +
          "• Axinos, 5′, Anna Maria Kakona, Calliopi Villy Kotoula [Greece]\n\n" +
          "• Toy, 11′, Natasha Smyrnaiou [Greece]\n\n" +
          "• Hantush – a fairytale, 6′12′′, Alexia Tsouni [Greece]"
      },
      "thumbnail": "media/The-Land-300x200.jpg"
    }
  },
  {
    "id": 2,
    "attributes": {
      "title": {
        "gr": "Τετάρτη 2/10, 20:00", 
        "en": "Wednesday 2/10, 20:00"
      },
      "subtitle": {
        "gr": "Photometria in Motion, Κατηγορία: Ταινίες Μικρού Μήκους (3ο μέρος), 144′",
        "en": "Photometria in Motion, Category: Short Films (3rd part), 144′"
      },
      "description": {
        "gr":
          "• Entropy, 14′18′′ , Bamdad Aghajani, Arian Navabi [Ιράν]\n\n" +
          "• Good to talk, 9′33′′ , Stephen Broekhuizen [Ιρλανδία]\n\n" +
          "• si mai no ens haguéssim separate, 14′13′′  , Marc Esquirol Cano [Ισπανία]\n\n" +
          "• Double bed, 6′17′′, Ali Khameparast Fard [Ιράν]\n\n" +
          "• Tornado, 6′17′′, Meisam Gheisizadeh [Ιράν]\n\n" +
          "• góos, 14′30′′, Φλώρα Ηλία [Ελλάδα]\n\n" +
          "• Xénia, 10′, Αλέξανδρος Κακούρης [Ελλάδα]\n\n" +
          "• Voulolimna, 18′, Νεφέλη Παπαϊωάννου [Ελλάδα]\n\n" +
          "• The Boar, 20′, Κωνσταντίνος Τσιόδουλος [Ελλάδα]",
        "en":
          "• Entropy, 14′18′′ , Bamdad Aghajani, Arian Navabi [Iran]\n\n" +
          "• Good to talk, 9′33′′ , Stephen Broekhuizen [Ireland]\n\n" +
          "• si mai no ens haguéssim separate, 14′13′′  , Marc Esquirol Cano [Spain]\n\n" +
          "• Double bed, 6′17′′, Ali Khameparast Fard [Iran]\n\n" +
          "• Tornado, 6′17′′, Meisam Gheisizadeh [Iran]\n\n" +
          "• góos, 14′30′′, Flora Ilia [Greece]\n\n" +
          "• Xénia, 10′, Alexander Kakouris [Greece]\n\n" +
          "• Voulolimna, 18′, Nefeli Papaioannou [Greece]\n\n" +
          "• The Boar, 20′, Konstantinos Tsiodoulos [Greece]",
      },
      "thumbnail": "media/The-Boar-300x162.jpg"
    }
  },
  {
    "id": 3,
    "attributes": {
      "title": {
        "gr": "Πέμπτη 3/10, 20:00",
        "en": "Thursday 3/10, 20:00"
      },
      "subtitle": {
        "gr": "Photometria in Motion, Κατηγορία: Ντοκιμαντέρ, 45′",
        "en": "Photometria in Motion, Category: Documentaries, 45′"
      },
      "description": {
        "gr":
          "• Transient Ties, 8′44′′, Michaela Nagyidaiová [Σλοβακία]\n\n" +
          "• Courage, 15′30′′, Star Bazancir, Jasmina Pusök [Σουηδία]\n\n" +
          "• The Life Beyond, Στυλιανός Παπαρδέλας [Ελλάδα]",
        "en":
          "• Transient Ties, 8′44′′, Michaela Nagyidaiová [Slovakia]\n\n" +
          "• Courage, 15′30′′, Star Bazancir, Jasmina Pusök [Sweden]\n\n" +
          "• The Life Beyond, Stylianos Papardelas [Greece]",
      },
      "thumbnail": "media/The-Life-Beyond-Στυλιανός-Παπαρδέλας-300x200.jpg"
    }
  },
  {
    "id": 4,
    "attributes": {
      "title": {
        "gr": "Παρασκευή 4/10, 20:00",
        "en": "Friday 4/10, 20:00"
      },
      "subtitle": {
        "gr": "Photometria in Motion, Κατηγορία: Animation, 100′",
        "en": "Photometria in Motion, Category: Animation, 100′"
      },
      "description": {
        "gr":
          "• PINA, 20′, Jeremy Depuydt, Giuseppe Accardo [Βέλγιο]\n\n" +
          "• Txotxongiloa, 10′, Sonia Estévez [Ισπανία]\n\n" +
          "• Golden Shot, 8′40′′, Gokalp Gonen [Τουρκία]\n\n" +
          "• Don’t forget to take medicines on time, 2′27′′, Xiaoxuan Han [Κίνα]\n\n" +
          "• Swwart Wovlve Wolve, 8′, William Harper [Ην. Πολιτείες Αμερικής]\n\n" +
          "• Anemometer, 6′, Wang-Chen Lee [Γερμανία]\n\n" +
          "• Winking out, 17′, Juliette Mauduit [Βέλγιο]\n\n" +
          "• Monk SEISHIN, 2′53′′, Ryotaro Miyajima [Ιαπωνία]\n\n" +
          "• Shakespeare for all Ages, 2′42′′, Hannes Rall [Γερμανία]\n\n" +
          "• NUMBERS, 9′, Deniz Türker [Τουρκία]\n\n" +
          "• STARRY WILL, 2′14′′, Jiajing Yin [Κίνα]\n\n" +
          "• Pink Mountain, 8′25′′, Thomas Kunstler [Ελλάδα]",
        "en":
          "• PINA, 20′, Jeremy Depuydt, Giuseppe Accardo [Belgium]\n\n" +
          "• Txotxongiloa, 10′, Sonia Estévez [Spain]\n\n" +
          "• Golden Shot, 8′40′′, Gokalp Gonen [Turkey]\n\n" +
          "• Don’t forget to take medicines on time, 2′27′′, Xiaoxuan Han [China]\n\n" +
          "• Swwart Wovlve Wolve, 8′, William Harper [USA]\n\n" +
          "• Anemometer, 6′, Wang-Chen Lee [Germany]\n\n" +
          "• Winking out, 17′, Juliette Mauduit [Belgium]\n\n" +
          "• Monk SEISHIN, 2′53′′, Ryotaro Miyajima [Japan]\n\n" +
          "• Shakespeare for all Ages, 2′42′′, Hannes Rall [Germany]\n\n" +
          "• NUMBERS, 9′, Deniz Türker [Turkey]\n\n" +
          "• STARRY WILL, 2′14′′, Jiajing Yin [China]\n\n" +
          "• Pink Mountain, 8′25′′, Thomas Kunstler [Greece]",
      },
      "thumbnail": "media/Txotxongiloa-Sonia-Estevez-300x169.jpg"
    }
  },
  {
    "id": 5,
    "attributes": {
      "title": {
        "gr": "Σάββατο 5/10, 20:00",
        "en": "Saturday 5/10, 20:00"
      },
      "subtitle": {
        "gr": "Photometria in Motion, Κατηγορία: Video Art, 96′",
        "en": "Photometria in Motion, Category: Video Art, 96′"
      },
      "description": {
        "gr":
          "• Urban Cut – Agonia Stratos, 3′, Lea Alquiroz [Ην. Βασίλειο]\n\n" +
          "• Hungry Ghost, Guido Devadder, 6′52′′, Kaitlin McSweeney [Βέλγιο]\n\n" +
          "• Motus, 4′10′′, Nelson Fernandes [Πορτογαλία]\n\n" +
          "• Periodic Table, 5′21′′, Jason Franzen [Ην. Πολιτείες Αμερικής]\n\n" +
          "• AbstrArt 27, 6′20′′, Luis Carlos Rodríguez García [Ισπανία]\n\n" +
          "• Flights, 4′53′′, Anne Gart [Ην. Βασίλειο]\n\n" +
          "• Night Ride from LA, 5′, Martin Gerigk [Γερμανία]\n\n" +
          "• Acrobat, 13′28′′, Shin Jungkyun [Κορέα]\n\n" +
          "• Simulakrum, 4′14′′, Vera Sebert [Αυστρία]\n\n" +
          "• The Empathy Machine, 9′28′′, Grant Sissons [Νότια Αφρική]\n\n" +
          "• KYKLOP, 15′, Telemach Wiesinger [Γερμανία]\n\n" +
          "• the manifest of it, 20′, Κωστής Αργυριάδης [Ελλάδα]\n\n" +
          "• The Life We Live Is Not Life Itself, 8′45′′, Ian Gibbins [Ελλάδα]",
        "en":
          "• Urban Cut – Agonia Stratos, 3′, Lea Alquiroz [Un. Kingdom]\n\n" +
          "• Hungry Ghost, Guido Devadder, 6′52′′, Kaitlin McSweeney [Belgium]\n\n" +
          "• Motus, 4′10′′, Nelson Fernandes [Portugal]\n\n" +
          "• Periodic Table, 5′21′′, Jason Franzen [USA]\n\n" +
          "• AbstrArt 27, 6′20′′, Luis Carlos Rodríguez García [Spain]\n\n" +
          "• Flights, 4′53′′, Anne Gart [Un. Kingdom]\n\n" +
          "• Night Ride from LA, 5′, Martin Gerigk [Germany]\n\n" +
          "• Acrobat, 13′28′′, Shin Jungkyun [Korea]\n\n" +
          "• Simulakrum, 4′14′′, Vera Sebert [Austria]\n\n" +
          "• The Empathy Machine, 9′28′′, Grant Sissons [South Africa]\n\n" +
          "• KYKLOP, 15′, Telemach Wiesinger [Germany]\n\n" +
          "• the manifest of it, 20′, Κostis Αrgyriadis [Greece]\n\n" +
          "• The Life We Live Is Not Life Itself, 8′45′′, Ian Gibbins [Greece]",
        },
      "thumbnail": "media/Photo-1-000000023-300x168.jpg"
    }
  },
  {
    "id": 6,
    "attributes": {
      "title": {
        "gr": "Δευτέρα 7/10, 20:00",
        "en": "Monday 7/10, 20:00"
      },
      "subtitle": {
        "gr": "Ο Περικλής στην Αμερική, John Cohen, 1988, 68′",
        "en": "Pericles in America, John Cohen, 1988, 68′"
      },
      "description": {
        "gr": "Η ταινία αυτή φιλοτεχνεί το μουσικό πορταίτο του Περικλή Χαλκιά, κλαρινίστα μετανάστη, και της ηπειρώτικης κοινότητας στην Αμερική, εξερευνώντας τις φιλοδοξίες, αλλά και την αμφιθυμία των Ελληνοαμερικανών. Καθώς κινείται μεταξύ Κουίνς, Νέας Υόρκης και βόρειας Ελλάδας, παρουσιάζει την παραδοσιακή μουσική της Ηπείρου και το πως αυτή ενώνει την Ηπειρώτικη κοινότητα σε όλο τον κόσμο. Η ταινία δεν βλέπει την Αμερική ως ένα χωνευτήρι λαών, αλλά ως ένα μέρος για να φτιάξει κανείς μια καλύτερη ζωή. Οι Ηπειρώτες που κερδίζουν το ψωμί τους εδώ έχουν γερά ριζωμένη την καρδιά τους στα βουνά της Ελλάδας.",
        "en": "This musical portrait of immigrant clarinetist Pericles Halkias and the Epirot-Greek community explores the aspirations and ambivalences of Greek-Americans. Moving between Queens, New York and northern Greece, it presents the traditional music of Epirus, showing how the music unites the Epirot community around the world. The film defines America not as a melting pot, but rather as a place to make a better living. The Epirots who earn their living here have their hearts planted firmly in the mountains of Greece."
      },
      "thumbnail": "media/Screenshot-2024-07-17-at-12.15.12-PM-1-300x238.png"
    }
  },
];