import MenuDrawer from '../components/global/MenuDrawer';
import PhotographersContent from '../components/photographers/PhotographersContent';

function PhotographersPage() {
  return (
    <>
      <MenuDrawer />
      <PhotographersContent />
    </>
  );
}

export default PhotographersPage;