import { Marker, Popup } from "react-leaflet";
import { Box, Typography, Button } from "@mui/material";
import DirectionsRoundedIcon from '@mui/icons-material/DirectionsRounded';
import L from "leaflet";
import { useLocaleState } from "../../context/LocaleStateContext";

const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

function ExhibitionsMarker({ index, position, exhibition, title, thumbnail, destinationCoordinates, setDestinationCoordinates, setShowAlert, userPosition, location }) {
  const { locale } = useLocaleState();

  const openIcon = new L.Icon({
    iconUrl: '/assets/blue_marker.png',
    shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
    iconSize: [27, 43],
    iconAnchor: [12, 41],
    popupAnchor: [0, -34],
    shadowSize: [41, 41],
  });

  const closedIcon = new L.Icon({
    iconUrl: '/assets/red_marker.png',
    shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
    iconSize: [27, 43],
    iconAnchor: [12, 41],
    popupAnchor: [0, -34],
    shadowSize: [41, 41],
  });

  function isOpen(exhibition) {
    if (!compareDate(exhibition)) return false;

    const openTime = exhibition.attributes.openTime;

    if (!openTime) return true;

    const currentDay = days[new Date().getDay()];
    const currentTime = parseFloat(`${String(new Date().getHours()).padStart(2, '0')}.${String(new Date().getMinutes()).padStart(2, '0')}`);
    const timeSlots = openTime[currentDay];

    if (timeSlots.length === 0) return false;

    return timeSlots.some(({ start, end }) => currentTime >= parseFloat(start) && currentTime <= parseFloat(end));
  }

  function compareDate(exhibition){
    const currentDate = parseFloat(`${new Date().getMonth() + 1}.${String(new Date().getDate()).padStart(2, '0')}`);
    const { start, end } = exhibition.attributes.duration;
    return currentDate >= parseFloat(`${start.month}.${start.day}`) && currentDate <= parseFloat(`${end.month}.${end.day}`);
  }

  function isIOS() {
    return (
      /iPad|iPhone|iPod/.test(navigator.userAgent) &&
      !window.MSStream
    );
  };

  function handleDirectionsClick(position) {
    if (isIOS() && destinationCoordinates.length>0) setShowAlert(true);
    setDestinationCoordinates(position);
  }

  function hasThumbnail(thumbnail) {
    return thumbnail !== "media/A_black_image.jpg";
  }

  function getLocale(item) {
    if (locale==="en") return item.en;
    return item.gr;
  }

  function isEnLocale() {
    return locale==="en";
  }

  return (
    <Marker
      key={index} 
      position={position}  
      icon={isOpen(exhibition[0]) ? openIcon : closedIcon} 
    >
      <Popup closeButton={false}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            width: '200px',
            padding: 0,
          }}
        >
          <Typography 
            sx={{
              fontSize: '0.8rem',
              fontWeight: 'bold',
              color: '#333',
              padding: 0,
              margin: 0,
            }}
            fontFamily='Comfortaa'
          >                   
            {getLocale(title)}
          </Typography>

          <Typography 
            sx={{
              fontSize: '0.7rem',
              color: '#333',
              padding: 0,
              margin: 0,
            }}
            fontFamily='Comfortaa'
          >                   
            {getLocale(location)}
          </Typography>

          <Typography 
            sx={{
              fontSize: '0.8rem',
              color: isOpen(exhibition[0]) ? '#5392CC' : '#C66F60',
              padding: 0,
              margin: 0,
            }}
            fontFamily='Comfortaa'
          >
            {isOpen(exhibition[0]) ? isEnLocale() ? "Open" : 'Ανοιχτή έκθεση' : isEnLocale() ? "Closed" : 'Κλειστή έκθεση'}	
          </Typography>  
          {hasThumbnail(thumbnail) &&
            <Box
              component="img"
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                height: '130px',
                borderRadius: '8px',
                objectFit: 'cover',
              }}
              alt="Χωρίς εικόνα"
              src={thumbnail}
            />
          }
          {userPosition &&
            <Button
              variant="contained"
              startIcon={<DirectionsRoundedIcon />} 
              sx={{
                backgroundColor: 'black',
                color: 'white',
                borderRadius: 1,
                textTransform: 'none', 
                marginTop: '8px', 
                fontSize: '0.8rem',
                fontFamily: 'Comfortaa'
              }}
              onClick={() => handleDirectionsClick(position)}
            >
              {isEnLocale() ? "Directions" : "Οδηγίες"}
            </Button>
          }
        </Box>
      </Popup>
    </Marker>
  );
}

export default ExhibitionsMarker;