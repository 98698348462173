import { useEffect } from "react";
import { Marker, Popup } from "react-leaflet";
import { Typography } from "@mui/material";
import L from "leaflet";
import { useLocaleState } from "../../context/LocaleStateContext";

function UserLocation({ destinationCoordinates, userPosition, setUserPosition }) {
  const { locale } = useLocaleState();

  useEffect(() => {
    getCurrentLocation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [destinationCoordinates]);

  const getCurrentLocation = () => {
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                const { latitude, longitude } = position.coords;
                setUserPosition({latitude, longitude});
                openGoogleMaps(latitude, longitude);
            },
            (error) => {
                console.error("Error getting location:", error);
            }
        );
    } else {
        console.error("Geolocation is not supported by this browser.");
    }
  };

  const markerIcon = new L.Icon({
    iconUrl: '/assets/yellow_marker.png',
    shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
    iconSize: [27, 43],
    iconAnchor: [12, 41],
    popupAnchor: [0, -34],
    shadowSize: [41, 41],
  });

  function openGoogleMaps(userLat, userLng) {
    if (destinationCoordinates.length > 0) {
      const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&origin=${userLat},${userLng}&destination=${destinationCoordinates[0]},${destinationCoordinates[1]}&travelmode=walking`;
      window.open(googleMapsUrl, '_blank');
    }
  };

  function isEnLocale() {
    return locale==="en";
  }
    
  return(
    <>
      {userPosition && 
        <Marker
          key={100} 
          position={[userPosition.latitude, userPosition.longitude]}  
          icon={markerIcon} 
        >
          <Popup closeButton={false}>
            <Typography 
              sx={{
                fontSize: '0.9rem', 
                fontWeight: 'bold', 
                color: '#2E7D32', 
                padding: '5px', 
                textAlign: 'center', 
                fontFamily: 'Comfortaa'
              }}
            >                   
              {isEnLocale() ? "You're here!" : "Βρίσκεστε εδώ!"}
            </Typography>
          </Popup>
        </Marker>
      }
    </>
  );
} 

export default UserLocation;