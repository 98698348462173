export const routes = {
  welcome: '/',
  introductionMenu: '/introduction-menu',
  exhibitions: '/exhibitions', 
  screenings: '/screenings',
  events: '/events',
  map: '/map',
  aboutUs: '/about-us',
  contact: '/contact',
  partners: '/partners',
  photographers: '/photographers',
}