import { Box, Typography, Divider } from '@mui/material';
import { useLocaleState } from '../../context/LocaleStateContext';

function PartnersBox({ images, textGr, textEn, marginTop=2 }) {
  const { locale } = useLocaleState();

  function isLongText(text) {
    return text.length > 30;
  }

  function getLocale() {
    if (locale==="en") return textEn;
    return textGr;
  }

  return(
    <>
      {isLongText(getLocale()) ? 
      <>
      <Divider/>
      <Typography 
        fontSize={{ xs: 15, sm: 16, md: 17 }}  
        marginTop={marginTop}
        textAlign="center"
        fontFamily='Comfortaa'
        padding={{ xs: 2, sm: 3, md: 4 }}
      >
        {getLocale()}
      </Typography>
      </>
      :
      <Divider><Typography fontFamily='Comfortaa' fontSize={{ xs: 15, sm: 16, md: 17 }}>{getLocale()}</Typography></Divider>
      }
      <Box sx={{width: "100%", height: "100%", backgroundColor: "white"}}>
        {images.map((image, index) => (
          <Box
            key={index}
            component="img"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: '130px',
              objectFit: 'scale-down',
              backgroundColor: 'white',
              marginTop: 2,
              marginBottom: 5
            }}
            alt="Χωρίς εικόνα"
            src={"partnersAssets/" + image}
          />
        ))}
      </Box>
    </>
  );
}

export default PartnersBox;