import React from 'react';
import { Typography, Box, Button } from "@mui/material";
import { useLocation } from "wouter";
import MenuDrawer from  "../components/global/MenuDrawer";
import { colors } from '../config/colors';
import { menuItems } from '../config/menuItems';
import { useLocaleState } from '../context/LocaleStateContext';

function IntroductionMenuPage() {
  const [, setLocation] = useLocation();
  const { locale } = useLocaleState();

  function handleButtonClick(route) {
    setLocation(route);
  };

  function getLocale(item) {
    if (locale==="en") return item.name.en;
    return item.name.gr;
  }

  return (
    <>
      <MenuDrawer />
      <Box 
        sx={{
          width: '100vw',
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <img 
          src="photometriaImage.png" 
          alt="Logo" 
          style={{ 
            width: "300vw",
            maxWidth: "300px", 
            height: "auto", 
          }} 
        />
        
        <Typography 
          fontSize={{ xs: 24, sm: 28, md: 30 }} 
          fontWeight={900}
          style={{ 
            marginTop: '2vh', 
            marginBottom: '2vh',
          }}
        >
          {"Constructed Realities"}
        </Typography>
        
        <Box 
          sx={{ 
            backgroundColor: colors.lightYellow, 
            width: '90vw',
            padding: 1.5,
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
            gap: 4,
            boxShadow: '0 3px 6px rgba(0, 0, 0, 0.1)',
            borderRadius: 2,
          }}
        >
          {menuItems.map((item, index) => (
            <Button
              key={index}
              sx={{
                color: colors.black,
                fontSize: '1.5rem',
                width: '100%',
                height: 'auto',
                textAlign: index % 2 === 0 ? 'left' : 'right',
                display: 'flex',
                justifyContent: index % 2 === 0 ? 'flex-start' : 'flex-end',
                alignItems: 'center',
              }}
              onClick={() => handleButtonClick(`${item.route}`)}
              style={{fontFamily: 'Comfortaa'}}
            >
              {getLocale(item)}
            </Button>
          ))}
        </Box>
      </Box>
    </>
  );
}

export default IntroductionMenuPage;
