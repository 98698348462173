import { Dialog, DialogTitle, DialogContent, DialogActions, Box, Typography, Button } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { colors } from "../../config/colors";
import { useLocaleState } from "../../context/LocaleStateContext";

function PhotographerDialog({ selectedPhotographer, setSelectedPhotographer, open, setOpen }) {
  const { locale } = useLocaleState();

  function handleCloseButtonClick() {
    setOpen(false);
    setSelectedPhotographer(null);
  };

  function getLocale(item) {
    if (locale==="en") return item.en;
    return item.gr;
  }

  return (
    <Dialog 
      open={open} 
      onClose={handleCloseButtonClick} 
      fullScreen 
    >
      <DialogTitle 
        sx={{ 
          backgroundColor: colors.lightYellow, 
          fontWeight: 'bold', 
          textAlign: 'center',
          padding: '16px',
        }}
        fontFamily='Comfortaa'
      >
        {selectedPhotographer && getLocale(selectedPhotographer.title)}
      </DialogTitle>

      <DialogContent 
        sx={{ 
          backgroundColor: colors.lightYellow, 
          padding: '40px 20px', 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center',
        }}
      >
        {selectedPhotographer?.imageUrl && 
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: '20px',
            }}
          >
            <img
              src={selectedPhotographer.imageUrl}
              alt={getLocale(selectedPhotographer.title)}
              style={{
                width: '100%',
                height: 'auto',
                borderRadius: 8,
                maxWidth: '100%',
              }}
            />
          </Box>
        }

        <Typography 
          sx={{ 
            color: '#333', 
            textAlign: 'left', 
            whiteSpace: 'pre-line',
          }}
        >
          {selectedPhotographer ? getLocale(selectedPhotographer.description)  : 'No description available'}
        </Typography>
      </DialogContent>

      <DialogActions 
        sx={{ 
          backgroundColor: colors.lightYellow, 
          justifyContent: 'center',
          padding: '25px',
        }}
      >
        <Button 
          onClick={handleCloseButtonClick} 
          variant="contained" 
          sx={{ 
            borderRadius: '10px', 
            display: 'flex', 
            alignItems: 'center', 
            backgroundColor: colors.lightYellow, 
  
            color: 'primary.main', 
            boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.3)',
            border: '2px solid rgba(0, 0, 0, 0.1)', 
          }}
        >
          <CloseIcon sx={{ color: 'black' }} />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default PhotographerDialog;