import { useState } from "react";
import { LocaleStateContext } from "./LocaleStateContext";

function LocaleStateProvider({ children }) {
  const [locale, setLocale] = useState('el');

  return (
    <LocaleStateContext.Provider 
      value={{ 
        locale: locale, 
        setLocale: setLocale, 
      }}
    >
      {children}
    </LocaleStateContext.Provider>
  );
}

export default LocaleStateProvider;