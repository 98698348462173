import { Dialog, DialogTitle, DialogContent, DialogActions, Box, Typography, Button } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { colors } from "../../config/colors";
import { useLocaleState } from "../../context/LocaleStateContext";

function ExhibitionsDialog({ selectedExhibition, setSelectedExhibition, open, setOpen }) {
  const { locale } = useLocaleState();

  const durationText = [{"gr": "Διάρκεια: ", "en": "Duration: "}];

  function hasThumbnail(exhibition) {
    return exhibition.imageUrl !== 'media/A_black_image.jpg';
  };

  function handleCloseButtonClick() {
    setOpen(false);
    setSelectedExhibition(null);
  };

  function getExhibitionDate(exhibition) {
    const startDate = exhibition.duration.start.day + "." + exhibition.duration.start.month;
    const endDate = exhibition.duration.end.day + "." + exhibition.duration.end.month;
    return startDate + " - " + endDate;
  }

  function getLocale(item) {
    if (locale==="en") return item.en;
    return item.gr;
  }

  return (
    <Dialog 
      open={open} 
      onClose={handleCloseButtonClick} 
      fullScreen 
    >
      <DialogTitle 
        sx={{ 
          backgroundColor: colors.lightYellow, 
          fontWeight: 'bold', 
          textAlign: 'center',
          padding: '16px',
        }}
        fontFamily='Comfortaa'
      >
        {selectedExhibition && getLocale(selectedExhibition.title)}
      </DialogTitle>

      <DialogContent 
        sx={{ 
          backgroundColor: colors.lightYellow, 
          padding: '40px 20px', 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center',
        }}
      >
        {selectedExhibition?.imageUrl && hasThumbnail(selectedExhibition) ? 
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: '20px',
            }}
          >
            <img
              src={selectedExhibition.imageUrl}
              alt={getLocale(selectedExhibition.title)}
              style={{
                width: '100%',
                height: 'auto',
                borderRadius: 8,
                maxWidth: '100%',
              }}
            />
          </Box>
          :
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: '20px',
            }}
          />
        }

        <Typography 
          sx={{ 
            color: '#333', 
            textAlign: 'left', 
            whiteSpace: 'pre-line',
          }}
        >
          {selectedExhibition ? getLocale(selectedExhibition.description)  : 'No description available'}
        </Typography>
        {selectedExhibition?.location &&
          <Typography 
            sx={{ 
              color: '#333', 
              fontWeight: 'bold',
              marginTop: '20px',
              textAlign: 'center',
            }}
          >
            {selectedExhibition && getLocale(selectedExhibition.location)}
          </Typography>
        }
        {selectedExhibition?.duration &&
          <Typography 
            sx={{ 
              color: '#333', 
              fontWeight: 'bold',
              marginTop: '10px',
              textAlign: 'center',
            }}
          >
            {getLocale(durationText[0]) + getExhibitionDate(selectedExhibition)}  
          </Typography>
        }
      </DialogContent>

      <DialogActions 
        sx={{ 
          backgroundColor: colors.lightYellow, 
          justifyContent: 'center',
          padding: '25px',
        }}
      >
        <Button 
          onClick={handleCloseButtonClick} 
          variant="contained" 
          sx={{ 
            borderRadius: '10px', 
            display: 'flex', 
            alignItems: 'center', 
            backgroundColor: colors.lightYellow, 
  
            color: 'primary.main', 
            boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.3)',
            border: '2px solid rgba(0, 0, 0, 0.1)', 
          }}
        >
          <CloseIcon sx={{ color: 'black' }} />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ExhibitionsDialog;